import Environment from "./base";

const environments: Environment = {
  isDevelopment: false,
  serviceHostName: "https://staging-service.pantavanij.com",
  clientID: "supplier",
  keycloakHostName: "https://staging-service.pantavanij.com/keycloak",
  keycloakRealm: "ptvn",
  supplierFrontend: "https://staging-supplier.pantavanij.com",
  supplierWebWorkFrontend: "http://stg.supplierwebwork.com",
  emarketPlaceFrontend: "https://staging-emarketplace.pantavanij.com",
  pantavanijIntelligenceFrontend: "https://staging-emarketplace.pantavanij.com/pi",
  lameSurvayFrontend: "https://stgcl-saq.pantavanij.com/loginFromSC",
  oneTrust: "https://cdn-apac.onetrust.com",
  oneTrustDataDomainScript: "455ce70e-987e-4d20-8865-0359614e5438",
  googleAnalyticKey: "G-6B9F3P4WEL",
  cocDocumentTH: "https://alpha-supplier.pantavanij.com/files/CPGCG-CoC-TH.pdf",
  cocDocumentEN: "https://alpha-supplier.pantavanij.com/files/CPGCG-CoC-EN.pdf",
  AccepAutoPOTH: "https://alpha-supplier.pantavanij.com/files/Supplier_Auto_Accept_PO_V3_Thai.pdf",
  AccepAutoPOEN: "https://alpha-supplier.pantavanij.com/files/Supplier_Auto_Accept_PO_V3_Eng.pdf",
  hotjar: {
    siteId: 3564232,
    hotjarVersion: 6,
  }
};

export default environments;
